<template>
  <Main>
    asdasdadasd holi sd
  </Main>
</template>

<script>
import Main from "@/components/system/main.vue";
import { alertController, onIonViewDidEnter } from "@ionic/vue";
import useAuth from "@/providers/auth";
import { useRouter } from "vue-router";

export default {
  name: "Logout",
  components: {
    Main,
  },
  setup() {
    const router = useRouter();
    const { logout } = useAuth();
    console.log('prev');
    onIonViewDidEnter(async () => {
      const alert = await alertController
        .create({
          class: "warning",
          header: "¿Deseas salir de la sesión?",
          message: "",
          buttons: [
            {
              text: "Confirmar",
              cssClass: "success",
              handler: () => {
                logout();
                router.replace({ name: "Login" });
              },
            },
            {
              text: "Cancelar",
              cssClass: "danger",
              handler: () => {
                router.back();
              },
            },
          ],
        });
      await alert.present();
      console.log('entro');
    });
    return { };
  },
};
</script>

<style></style>
